.signInError{
    max-width: 700px;
    margin: auto;
    padding: 10px;

    animation: 1s fadeIn;
    animation-fill-mode: forwards;
    
    visibility: hidden;
    
}
.signInError h5{
    color:red;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}
.signInError p{
     margin-top: 20px;
    text-align: center;
    font-size: 16px;
}
.waitingForUserInfo{
  text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

}
.loadingMsg{
  display: inline-block;
  background: white;
  padding: 10%;
  border-radius: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loadingRes{
  padding-top: 30px;
}

@keyframes fadeIn {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
