.input {
  /* display: flex; */
  align-items: baseline;
}

.color-input {
  width: 80px;
}

.color {
  display: flex;
  justify-content: space-between;
}

.color-label {
  margin-right: 10px;
  font-weight: 600;
}

/* .input .inputLabel {
  font-weight: normal;
  text-transform: capitalize;
  color: #0650b3db;
  background: #fff;
  padding: 5px 0;
} */

.textLabel {
  font-weight: normal;
  text-transform: capitalize;
  color: #0650b3db;
  background: #fff;
  padding: 5px 0;
  display: flex;
}
.textLabel .info-icon {
  padding-left: 3px;
}

.required {
  color: red;
}

/* .input .inputInput {
  padding: 1px;
  padding-left: 6px;
  padding-right: 6px;
  background: #ebecf14f;
  margin-right: 10px;
  min-width: 100px;
} */
.inputInput {
  background: transparent;
  width: 90%;
}

.textarea textarea {
  max-width: 100% !important;
  margin-top: 4px;
  margin-bottom: 4px;
}

.dropdown button {
  width: 100%;
  background-color: #e2e2e2;
}
