.modal-title {
  text-transform: capitalize;
}

.failed {
  font-size: 16px;
  color: red;
  text-align: center;
}

.failedTxt {
  font-size: 14px;
  color: grey;
  text-align: center;
}
.successMsg {
  font-size: 16px;
  color: green;
  text-align: center;
}
.successTxt {
  font-size: 14px;
  color: grey;
  text-align: center;
}
small {
  text-transform: capitalize;
}
.moduleDescription {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  width: 100%;
}
.moduleDescription .form-control {
  margin-left: 10px;
}
.header-bar .form-control {
  display: inline-block;
  width: auto;
  border-color: #a7a7a7;
}
.moduleDescription .field {
  display: flex;
  align-items: baseline;
}
.edit-input {
  background-color: white;
}
textarea.form-control {
  margin-top: 16px;
  border-radius: 4px !important;
  max-height: 80px;
  padding: 0.375rem 0.75rem;
  resize: none;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  line-height: 2;
}
textarea.form-control:focus {
  outline: none !important;
  border-color: red !important;
}

.moduleDescription .field .fieldLabel {
  font-weight: normal;
  text-transform: capitalize;
  color: #0650b3db;
  background: #fff;
}
.textInput {
  border: 1px solid #e3e3e3;
}

textarea.form-control .moduleDescription .field .fieldInput {
  padding: 1px;
  padding-left: 6px;
  padding-right: 6px;
  background: #ebecf14f;
  margin-right: 10px;
  min-width: 100px;
}
.moduleDescription .otherFields {
  width: 100%;
}
.moduleDescription .otherFields .fieldInput {
  background: transparent;
  width: 90%;
}

.headerRow {
  text-transform: capitalize;
}
.minMaxInputFormGroup {
  padding: 10px;
}
.minMaxInputGroup {
  display: flex;
  align-items: baseline;
}
.minMaxInputGroup .minMax {
  margin-right: 10px;
  display: flex;
  align-items: baseline;
}
.minMaxInputGroup .minMax span {
  font-size: 12px;
  padding-right: 6px;
}
.minMaxInputGroup .minMax input {
  font-size: 14px;
}
.customMultiSelectContainer {
  position: relative;
}
.customMultiSelectContainer .customMultiSelectGroup {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.customMultiSelectButton {
  position: relative;
  background: #fff;
  display: inline-block;
  min-width: 160px;
  padding: 6px;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  cursor: pointer;
}
.customMultiSelectButton.active {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.customMultiSelectList {
    max-height: 200px;
    overflow-y: auto;
}

.customMultiSelectList ul {
  list-style: none;
  padding: 0;
  padding: 10px;
}
.customMultiSelectList ul li {
  display: flex;
  margin-bottom: 2px;
}
.customMultiSelectList ul li.customMultiSelectListItem {
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
}

.customMultiSelectList .customMultiSelectLabel {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.customMultiSelectList ul li input {
  width: 14px;
  margin-right: 6px;
}

.animation-basic-enter {
  opacity: 0;
}
.animation-basic-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.animation-basic-exit {
  opacity: 1;
}
.animation-basic-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.sidebarHeader h5 {
  margin: 0px;
}

.fixedHeight {
  max-height: 400px;
  overflow-y: auto;
}

/* width */
.fixedHeight::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.fixedHeight::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.fixedHeight::-webkit-scrollbar-thumb {
  background: #06509e;
  border-radius: 10px;
}

/* Handle on hover */
.fixedHeight::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.columnOuterContainer {
  background: #8f8f8f0d;
  padding: 10px;
  padding-right: 20px;
}
.ant-table-measure-row,
.ant-table-cell.ant-table-selection-column {
  min-width: 50px;
  max-width: 51px;
}
.paddingBottom {
  padding-bottom: 140px;
}
.fixedbottom {
  position: fixed;
  bottom: -7px;
  display: block;
  right: 0px;
  width: 620px;
  text-align: revert;
  padding: 10px;
  padding-left: 16px;
  background: #fff;
  padding-right: 16px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
}
.mysidebar.larger {
  width: 960px;
}
.mysidebar.larger .fixedbottom {
  width: 960px;
}
.textNormal {
  text-transform: none;
}
.fiberMP .form-group {
  display: flex;
  align-items: baseline;
}
.fiberMP .form-group input {
  width: 40%;
}
.fiberMP .form-group label {
  padding-right: 4px;
}

.propertyInputRow .input-group .form-control:not(:first-child):not(:last-child),
.propertyInputRow .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0px;
}

.labVantageFieldsTable thead tr th {
  background: #fff;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  font-weight: 500;
}

.calulcationParameters {
  background: rgb(0 0 0 / 8%);
  margin: 0;
  padding: 10px;
  margin-bottom: 10px;
}
.btnDiv {
  background: #ffffff;
  text-align: center;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
  border: 1px solid #0000003d;
  padding: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.btnDiv:hover,
.btnDiv:focus {
  border: 1px solid #016ebea1;
  background: #016ebea1;
  color: #fff;
}
.calulcationParameters {
  background: #fff;
  margin: 0;
  padding: 2px;
  margin-bottom: 10px;
  border-bottom: 1px solid #016ebe;
  padding-left: 0;
  color: #016ebe;
}
.pTitle {
  background: #ebeaea;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.labVantageFieldsTable tr td {
  font-size: 14px;
}
.lvRow {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #016ebe54;
  margin: 2px;
  border-radius: 4px;
  font-size: 12px;
}
.lvRow:hover {
  background: #016ebe;
  color: #fff;
}
.labvantageContainer {
  padding: 0px;
  display: flex;
  margin-bottom: 12px;
}
.lvRow.activeItem {
  background: #016ebe;
  color: #fff;
}

.middle {
  text-align: center;
  padding-top: 40%;
  transform: translateY(-50%);
}

.modal-title.delete {
  display: flex;
  align-items: center;
  color: red;
}

.deleteWanringMsg {
  text-align: center;
}

.select-flex {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  max-width: 301px;
  width: 100%;
  margin-left: auto;
}
.select-flex label {
  padding-right: 10px;
}
.select-flex select {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid blue;
}
.bordered-container {
  border: 1px solid #c1c1c1;
  padding: 16px;
  padding-right: 26px;
}

div[data-module="sam"] small > span,
div[data-module="sam"] div.fieldLabel > span,
div[data-module="sam"] div.headerRow span {
  text-transform: uppercase;
}
.fixedLoader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  background: #ffffffc7;
  z-index: 99;
}
.fixedLoader img.loaderr {
  top: 45%;
  position: relative;
}
.visualGraphTitle {
  background: #e9e9e9;
  font-weight: bold;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 23px;
}
