body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.details-empty {
  height: 100vh;
  position: relative;
}
.details-empty .details-preloader {
  position: absolute;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  max-width: 90px;
  opacity: 78%;
}

.status-btn {
  margin: 0;
  padding: 5px 8px;
  align-items: center;
  width: 70%;
}

.link-btn {
  color: #007bff !important;
}

.delete-btn {
  display: flex;
  align-items: center;
}

.screenLoader{
  position: fixed;
  left: 0;
  right: 0;
  top: 220px;
  bottom: 0;
  text-align: center;
 
}
.details-empty.screenLoader .details-preloader{
  top: auto;
  transform: none;
}
.details-empty.screenLoader  h5{
 font-size: 14px;
}
