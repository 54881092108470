:root{
  --point-radius: 2;
  --point-stroke:0.5;
  }


.vivbilityIcon{
  max-width: 14px;
    margin-right: 4px;
}
.svgViewer{
  margin: auto;
  text-align: center;
}
.scale-drop-down{
  font-size: 12px;
  width: 120px;
}
.svgContainer g.svgPathGroup.selected{
  fill:rgb(33 150 243 / 52%);
  stroke:#d2b61b  !important;
}
.svgContainer g.part.selected{
  /* fill: rgb(255 0 0 / 71%);
  stroke: rgb(255 0 0 / 71%); */
/* fill:inherit;
  stroke:inherit;  */
}
.svgContainer g path{
  fill-opacity: 0.4;
}
.svgContainer g path.selected{
  fill:rgb(33 150 243 / 52%);
  stroke: #030202  !important;
}
.svgContainer{
  margin-left: 220px;
}
.outer{
  position: relative;
} 
.layersPannel{
  top: 0;
  max-width: 220px;
  background: #f6f6f6;
  position: absolute;
  z-index: 1;
  bottom: 0;
  overflow-y: auto;
  width: 100%;

}
.optionBar{
  /* box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28); */
    position: relative;
    box-sizing: border-box;
    margin-bottom: 4px;
    padding: 4px;
    padding-right: 10px;
    padding-left: 10px;
}

.optionBar ul{
  list-style: none;
  text-align: left;
  padding-left: 0;
  display: flex;
  font-size: 12px;
}
.optionBar ul li{margin-left: 10px;}
.optionBar ul li span.label{font-weight: 500;}
.optionBar ul li img{
  max-width: 18px;
  cursor: pointer;
}
.optionBar ul li span.btn{
  background: #2196f3;
  padding: 2px 6px;
  border: 1px solid #2196f3;
  color: #ffffff;
  margin-top: 0px;
}
.optionBar ul li select{
min-width: 100px;
}
.optionBar ul li select.blue{
  min-width: 100px;
  }
  .infoArea{
    display: flex;
    justify-content: space-between;
  }
.optionBar ul li span.txt{    background: #e3e3e3;
  padding: 2px 6px;
 min-width: 100px;
 display: inline-block;
 margin-left: 4px;
}
 

.layersPannel ul{
  list-style: none;
  text-align: left;
  padding-left: 0;
 
}
.layersPannel ul li{
  width: 200px;
  white-space: pre-line;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
 
  justify-content: flex-start;
  margin-bottom: 3px;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #80808040;
}
.layersPannel ul li span{
  margin-right: 2px;
}
.layersPannel ul li span.deletePart{
  margin-left: auto;
  padding-left: 4px;
  padding-right: 10px;
  cursor: pointer;
}
.layersPannel ul li span.deletePart:hover{
  color: red;
}
.layersPannel ul.paths{
  position: absolute;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.layersPannel ul.title{
  position: absolute;
  z-index: 1;
  overflow: hidden;
  top: 0;
  background: #2196F3;
  color: #fff;
  padding-top: 7px;
  left: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}
.resetOrigin{
  flex-direction: row;
  justify-content: space-evenly;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






.menuContextContainer{
  border: 1px solid #ffffff2d;
  border-radius: 4px;
  box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
  
 

  position: fixed;
  width: 200px;
   
  
  box-sizing: border-box;
  z-index: 99;
}
.menuContextContainer ul{
  list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
}
.menuContextContainer ul li{
  background-color: #c3c3c3;
  color: #282c34;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  cursor: pointer;
}
.menuContextContainer ul li:hover{
  background-color: #ededed;
 
}

.propertyPane{
  position: absolute;
  background: #edededf5;
  z-index: 1;
  width: 250px;
  min-height: 100px;
  padding: 10px;
  /* margin-left: 17px; */
  margin-top: 2px;
  font-size: 10px;
  right: 30px;
  
}
.propertyPane div{
display: flex;
margin-bottom: 3px;
text-align:left;
}
.propertyPane .label{
padding-right: 10px;
font-weight: 600;
width:100px;
text-align:left;
}
.propertyPane inpu.form-control{
  border-color: #a7a7a7;
}





.svgEditorPopup{
  position: absolute;
  z-index: 99;
  background: #ffffff5c;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.svgEditorPopup .popup{
  position: relative;
  max-width: 420px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.svgEditorPopup .popup .header{
  text-align: left;
  border-bottom: 1px solid #aaa9a9;
  padding-bottom: 5px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.svgEditorPopup .popup .header button{
cursor: pointer;
}

.svgEditorPopup .popup .content{
  text-align: left;
  padding: 10px;
  font-size: 14px;
  color: #808080;
}
.svgEditorPopup .popup .content select{
  min-width: 200px;
  padding: 2px;
  margin: 10px;
  margin-top: 20px;
  border-color: #0a4a7d;
}
.screenLoader{
  position: fixed;
  left: 0;
  right: 0;
  top: 220px;
  bottom: 0;
  text-align: center;
}

.origin{
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
}
.origin div{
  display: flex;
  width: 100%;
}
.origin span{
  width: 40px;
  background-color: rgb(255, 255, 255);
  margin-left: 2px;
  margin-right: 2px;
  padding: 2px;
   
}
.pH{
  color: black;
  background: #d6d3d3;
  font-size: 14px;
  /* padding-left: 20px; */
  margin-top: 12px;
}

.svg .header-btn-grp{

}


.svg  .header-title-txt{
  font-size: 1em;
}
.propertyPane .form-control{
  border-color: #a7a7a7;
  font-size: 1em;
  height: auto;
  padding: 2px 6px;
  background: #f2f2f2;
}
.svg  .form-control{
  padding: 2px;
  height: auto;
  font-size: 1em;
}

.svg .header-btn-grp .form-group, .svg .header-btn-grp .form-group.search-box, .svg .header-btn-grp  .btn.btn-outline-info{
  font-size: 1em;
}

.editButton{
  border-radius: 3px;
  color: #fff; 
  background-color: #1890ff;
  border: 1px solid #1890ff;
  width: 100%; /* Set all disabled buttons to 100% width */
}

.editButtonDisabled{
  border-radius: 3px;
  color: #000; 
  background-color: lightgrey;
  border: 1px solid lightgrey;
  width: 100%; /* Set all disabled buttons to 100% width */
}

.originPoint{
  
  cursor: pointer;
  stroke: red;
  stroke-width:var( --point-stroke);
  fill: rgb(222 226 230 / 51%);
  r:var(--point-radius);

}

.configPoint{
  cursor: pointer;
  stroke-width:var( --point-stroke);
  r:var(--point-radius);
}

.originPoint:hover, .originPoint:focus{
  stroke: yellow;
}
.originPoint.selected{
  fill: red;
  stroke: #fff;
  /* stroke-width: 4px; */
}

.calulatedOriginPoint{
  stroke: yellow;
  font-size: 9px;

}
.opacity{
  font-weight: light;
}
@media all and (max-width: 1600px) {
  .svg  .header-title-txt{
    font-size: 0.8em;
  }
  .svg  .form-control{
    padding: 2px;
    height: auto;
    font-size: 0.8em;
  }
  .svg .header-btn-grp .form-group, .svg .header-btn-grp .form-group.search-box, .svg .header-btn-grp  .btn.btn-outline-info{
    font-size: 0.8em;
  }
  .svg .header-btn-grp  .btn.btn-outline-info, .svg .header-btn-grp  .btn.btn-outline-danger, .svg .header-btn-grp  .btn.btn-primary{
    padding: 4px 12px;
    margin: 4px 1px;
    font-size: 0.8em;
  }
 
  
}

/* The switch - the box around the slider */
/* .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
} */

/* Hide default HTML checkbox */
/* .switch input {
  opacity: 0;
  width: 0;
  height: 0;
} */

/* The slider */
/* .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} */

/* Rounded sliders */
/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */