body {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.dropdown-menu {
  padding-right: 10px;
  padding-left: 10px;
}
.main-panel > .content {
  padding: 0 30px 50px;
  position: relative;
  padding-top: 150px;
  padding-bottom: 8px;
}
.main-panel > .content.bg-dark {
  padding: 0 30px 150px;
  position: relative;
  padding-top: 150px;
  padding-bottom: 140px;
}

.tileLink p {
  color: #2c2c2c;
}

.ant-table-thead > tr > th {
  color: #f96332;
  font-size: 1.05rem;
  border: 0;
  font-weight: 500;
  border-bottom: 1px solid #f96332;
  min-width: 148px;
}
.ant-table-thead > tr:first-child {
  min-width: 110px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px 7px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f96332;
}

.ant-btn-primary > i {
  padding-right: 10px;
}

.ant-tag-checkable-checked {
  background-color: transparent;
}

.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: lightgrey !important;
}

.ant-tag-checkable:hover,
.ant-tag-checkable-checked:hover {
  color: black;
  background-color: transparent;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: black;
}

.curation-legends {
  margin-top: 16px;
  position: absolute;
  margin-left: 10em;
  margin-right: 10em;
  z-index: 1;
  overflow: auto;
  display: flex;
  width: 75%;
  gap: 8px;
  justify-content: center;
}

.curation-upload div.ant-upload-list-item {
  border: 0 !important;
  color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0650b3db;
  text-shadow: 0 0 0.25px currentcolor;
  font-size: 16px;
  font-weight: 600;
}

.ant-tabs-tab-btn {
  font-size: 14px;
}

.action-btn {
  color: #0f059e;
  font-size: 12px;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu-title-content {
  transition: color 0.3s;
  font-size: 15px;
}

.propertyInputRow .ant-input-number-group-wrapper {
  display: block;
}

.ant-upload.ant-upload-drag {
  border: 2px dashed #d9d9d9;
}