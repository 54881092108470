.details {
  padding: 76px 32px 40px 32px;
  margin-right: 0;
}

.details-col {
  padding-left: 20px;
  gap: 8px;
}
.title {
  font-size: 44px;
  font-weight: 550;
  padding-top: 24px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.description {
  font-size: 18px;
  padding: 0 16px;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.btn-geometry {
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #89e5ff 0%, #5468ff 100%);
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%),
    inset 0px -3px 0px rgb(58 65 111 / 50%);
  padding: 0 32px;
  border-radius: 6px;
  color: #fff;
  height: 48px;
  font-size: 18px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.btn-geometry:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
  transform: translateY(-2px);
}

.btn-geometry:active {
  box-shadow: inset 0px 3px 7px #3c4fe0;
  transform: translateY(2px);
  outline: none;
}

.btn-material {
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  height: 48px;
  padding: 0 32px;
  font-size: 18px;
  border-radius: 6px;
  color: #36395a;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.btn-material:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}

.btn-material:active {
  box-shadow: inset 0px 3px 7px #d6d6e7;
  transform: translateY(2px);
  outline: none;
}
