.headerIcon {
  vertical-align: top;
  padding-right: 4px;
}
.navbar-nav {
  align-items: center;
}

.navbar-nav .nav-link {
  display: flex;
  align-items: center;
}
.header-title-txt {
  display: flex;
  align-items: center;
  gap: 16px;
}
.header-title-txt-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
@media only screen and (max-width: 1360px) {
  .header-bar .content {
    flex-direction: column;
  }
  .header-btn-grp {
    float: none;
    display: flex;
    align-items: baseline;
    margin-left: auto;
    background: #e3e3e3;
    width: 100%;
    justify-content: end;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
