.upload-section {
  border: solid 1px;
  height: 98%;
  background-color: #0650b3db;
  padding: 2%;
}

.upload-section h6 {
  color: white;
  font-weight: 600;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.dropdown button {
  margin: 0;
  color: black;
}

.error-msg {
  color: red;
}

.uploadFileTable tr {
  color: white;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.uploadFileTable thead tr th,
.uploadFileTable tr td {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.uploadFileTable tr td select.custom-select {
  max-width: 100px;
  background-color: transparent;
  color: white;
  font-size: 12px;
  max-height: 22px;
  /* padding: 0px; */
  padding-top: 0px;
  padding-bottom: 0;
}

.uploadFileTable tr td select option {
  color: black;
}
