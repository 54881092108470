.gridContainer .card {
  box-shadow: none;
}

.ant-table-footer {
  padding: 2px 16px;
}

.ant-table-pagination.ant-pagination {
  margin: 6px 12px 2px 12px;
}
